var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MetaMaskOnboarding from "@metamask/onboarding";
import { encrypt, recoverTypedSignature, } from "@metamask/eth-sig-util";
import { toChecksumAddress } from "ethereumjs-util";
import { ethers } from "ethers";
import metadata from "./bin/contracts/PiggyBank.json";
import metadataNFT_PHIMAGIC from "./bin/contracts/PHIMAGIC.json";
import metadataNFT_PHIMAGIC_721 from "./bin/contracts/PHIMAGIC_721.json";
import metadataNFT_ERC20 from "./bin/contracts/PHIMAGIC_ERC20.json";
import Plotly from "plotly.js";
let account;
let piggybankFactory;
let piggybankContract;
let NFTFactory;
let hstFactory;
let NFTContract;
let hstContract;
let lastaddress;
let lastaddressNFT;
let lastaddressNFT_721;
let ethereum = window.ethereum;
const { isMetaMaskInstalled } = MetaMaskOnboarding;
let provider = new ethers.providers.Web3Provider(window.ethereum);
let signer = provider.getSigner();
//const ethereumButton = document.querySelector('.enableEthereumButton');
const Withdraw = document.getElementById("GFG_DIV2");
const Deposit = document.getElementById("GFG_DIV3");
const Deploy = document.getElementById("GFG_DIV4");
const createToken = document.getElementById("GFG_DIV5");
const watchAsset = document.getElementById("GFG_DIV6");
const DeployNft_ERC1551 = document.getElementById("GFG_DIV7");
const DeployNft_ERC721 = document.getElementById("GFG_DIV17");
const SignV4 = document.getElementById("GFG_DIV9");
const EncDEc = document.getElementById("GFG_DIV10");
const sendEIP1559Button = document.getElementById("GFG_DIV11");
const getURI_721 = document.getElementById("GFG_DIV12");
const Mint_721 = document.getElementById("GFG_DIV8");
const setURI_721 = document.getElementById("GFG_DIV16");
const setURI_1551 = document.getElementById("GFG_DIV13");
const getURI_1551 = document.getElementById("GFG_DIV14");
const Mint_1551 = document.getElementById("GFG_DIV15");
//Withdraw.addEventListener('click', () => {
//PiggybankContractWithdraw();
//});
function RMD() {
    var div = document.getElementById("GFG_DIV");
    div.remove();
}
// Authorize
function web3Storage() {
    return __awaiter(this, void 0, void 0, function* () {
        $.ajax({
            type: "POST",
            url: "./curl/web3.php",
            data: {
                filename: "The Fifth Element.png",
            },
            success: function (data) {
                alert(data);
            },
        });
    });
}
function web3StorageGetInfo() {
    return __awaiter(this, void 0, void 0, function* () {
        $.ajax({
            url: "./curl/web3StorageInfo.php",
            success: function (data) {
                alert(data);
            },
        });
    });
}
function EncDEcPHP() {
    return __awaiter(this, void 0, void 0, function* () {
        jQuery("#ImageEncDec").attr("src", "encdec.php");
        //document.getElementById("ImageEncDec").src = 'encdec.php';
    });
}
//EncDEcPHP();
//web3Storage();
//web3StorageGetInfo()
getAccount();
function getAccount() {
    return __awaiter(this, void 0, void 0, function* () {
        // @ts-ignore
        //const installed = isMetaMaskInstalled();
        const accounts = yield provider.send("eth_requestAccounts", []);
        account = accounts[0];
        //requestPermissions();
        //showAccount.innerHTML = account;
        // read a record
        $.ajax({
            type: "POST",
            url: "publickey_read.php",
            data: {
                public_key: account,
            },
            success: function (data) {
                //alert(data);
                var d2 = data;
                var wtext = "Welcome : ";
                if (d2 === "")
                    wtext = " ";
                // @ts-ignore
                document.getElementById("auth").innerHTML = wtext.concat(d2);
            },
        });
        // read plotly plot record
        function testAjax() {
            return __awaiter(this, void 0, void 0, function* () {
                return Promise.resolve($.ajax({
                    type: "POST",
                    url: "data4.php",
                    // @ts-ignore
                    success: function (data) {
                        //alert(data);
                    },
                }));
            });
        }
        testAjax()
            .then((data) => {
            var parse = JSON.parse(data);
            //document.getElementById("demo").innerHTML = parse.array2;
            function fetchJSON(parse) {
                return __awaiter(this, void 0, void 0, function* () {
                    const data = [{
                            x: parse.array1,
                            y: parse.array2,
                            z: parse.array3,
                            colorscale: "Viridis",
                            opacity: 1.0,
                            type: "surface",
                            lighting: {
                                ambient: 0.95,
                                roughness: 1.0,
                                specular: 0.0,
                                diffuse: 0.0,
                                fresnel: 0.0,
                            },
                            contours: {
                                x: {
                                    show: true,
                                    usecolormap: true,
                                    highlightcolor: "white",
                                },
                                y: {
                                    show: true,
                                    usecolormap: true,
                                    highlightcolor: "white",
                                },
                                z: {
                                    show: true,
                                    usecolormap: true,
                                    highlightcolor: "white",
                                    project: {
                                        z: true,
                                    },
                                },
                            },
                            colorbar: {
                                x: 1.0,
                                len: 0.5,
                                tickcolor: "white",
                                tickfont: {
                                    color: "white",
                                    family: "Arial",
                                    size: 13,
                                },
                                dtick: 0.3,
                                outlinecolor: "white",
                            },
                        }];
                    const layout = {
                        title: {
                            text: "Sine distribution, joined density",
                            yref: "paper",
                            y: 1,
                            font: {
                                color: "white",
                                size: 24,
                            },
                        },
                        paper_bgcolor: "rgba(0, 0, 0, 0)",
                        scene: {
                            camera: {
                                center: {
                                    x: 0.0,
                                    y: 0.0,
                                    z: 0.0,
                                },
                                eye: {
                                    x: -1.5,
                                    y: -1.5,
                                    z: 1.4,
                                },
                            },
                            xaxis: {
                                showspikes: false,
                                zeroline: true,
                                zerolinecolor: "white",
                                nticks: 15,
                                ticks: "outside",
                                title: {
                                    text: "X",
                                    font: {
                                        color: "white",
                                    },
                                },
                                tickfont: {
                                    color: "white",
                                },
                            },
                            yaxis: {
                                showspikes: false,
                                zeroline: true,
                                zerolinecolor: "white",
                                nticks: 15,
                                ticks: "outside",
                                title: {
                                    text: "Y",
                                    font: {
                                        color: "white",
                                    },
                                },
                                tickfont: {
                                    color: "white",
                                },
                            },
                            zaxis: {
                                showspikes: false,
                                zeroline: true,
                                zerolinecolor: "white",
                                nticks: 15,
                                ticks: "outside",
                                title: {
                                    text: "Z",
                                    font: {
                                        color: "white",
                                    },
                                },
                                tickfont: {
                                    color: "white",
                                },
                            },
                        },
                        autosize: true,
                        width: 1000,
                        height: 1000,
                        margin: {
                            l: 65,
                            r: 50,
                            b: 50,
                            t: 70,
                        },
                    };
                    Plotly.newPlot("myDiv", data, layout);
                });
            }
            fetchJSON(parse);
            document.getElementById("GFG_DIV2").style.visibility = "visible";
            document.getElementById("GFG_DIV3").style.visibility = "visible";
            document.getElementById("GFG_DIV4").style.visibility = "visible";
            document.getElementById("GFG_DIV5").style.visibility = "visible";
            document.getElementById("GFG_DIV6").style.visibility = "visible";
            document.getElementById("GFG_DIV7").style.visibility = "visible";
            document.getElementById("GFG_DIV8").style.visibility = "visible";
            document.getElementById("GFG_DIV9").style.visibility = "visible";
            document.getElementById("GFG_DIV10").style.visibility = "visible";
            document.getElementById("GFG_DIV11").style.visibility = "visible";
            document.getElementById("GFG_DIV12").style.visibility = "visible";
            document.getElementById("GFG_DIV13").style.visibility = "visible";
            document.getElementById("GFG_DIV14").style.visibility = "visible";
            document.getElementById("GFG_DIV15").style.visibility = "visible";
            document.getElementById("GFG_DIV16").style.visibility = "visible";
            document.getElementById("GFG_DIV17").style.visibility = "visible";
            // document.getElementById('myImageId').style.visibility = 'visible';
        })
            //.then (PiggybankContractDeploy())
            //.then (PiggybankContractWithdraw())
            .catch((error) => console.log("Error:", error));
    });
}
sendEIP1559Button.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield ethereum.request({
        method: "eth_sendTransaction",
        params: [
            {
                from: account,
                to: account,
                value: e2w(0.01),
                //gasLimit: '0x5028',
                //maxFeePerGas: '0x2540be400',
                //maxPriorityFeePerGas: '0x3b9aca00',
            },
        ],
    });
    console.log(result);
});
function stringifiableToHex(value) {
    return ethers.utils.hexlify(Buffer.from(JSON.stringify(value)));
}
EncDEc.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let encryptionPublicKey;
        let ciphertext;
        yield ethereum
            .request({
            method: "eth_getEncryptionPublicKey",
            params: [account],
        })
            .then((result) => {
            encryptionPublicKey = result;
            // alert(result);
            ciphertext = stringifiableToHex(encrypt({
                publicKey: encryptionPublicKey,
                data: "bla bla",
                version: "x25519-xsalsa20-poly1305",
            }));
        });
        yield ethereum
            .request({
            method: "eth_decrypt",
            // @ts-ignore
            params: [ciphertext, ethereum.selectedAddress],
        })
            .then((result) => {
            alert(result);
        });
    }
    catch (error) {
        console.log(`Error: ${error.message}`);
    }
});
DeployNft_ERC1551.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    //NFTFactory = new ethers.ContractFactory(metadataNFT.abi, metadataNFT.bytecode, signer);
    NFTFactory = new ethers.ContractFactory(metadataNFT_PHIMAGIC.abi, metadataNFT_PHIMAGIC.bytecode, signer);
    try {
        NFTContract = yield NFTFactory.deploy();
        yield NFTContract.deployTransaction.wait();
    }
    catch (error) {
        console.log("Deployment Failed");
        throw error;
    }
    if (NFTContract.address === undefined) {
        return;
    }
    lastaddressNFT = NFTContract.address;
    console.log(`Contract mined! address: ${NFTContract.address} transactionHash: ${NFTContract.deployTransaction.hash}`);
});
Mint_1551 === null || Mint_1551 === void 0 ? void 0 : Mint_1551.addEventListener("click", listenerFunction);
function listenerFunction(ev) {
    return __awaiter(this, void 0, void 0, function* () {
        //const address = '0x9e279ff142633763867e41924d03bd1b2ad03289'; // Polygon
        const address = "0x756a566f73e4ff3cf98e83995957ed1e8bcff694"; // Polygon
        //const address = '0x9E279ff142633763867E41924D03BD1B2AD03289'; //Goerli
        const URI = "";
        let utf8Encode = new TextEncoder();
        const data = utf8Encode.encode(URI);
        const contractABI = [
            "function mint(address account, uint256 id, uint256 amount, bytes memory data)",
        ];
        const ERC1551Token = new ethers.Contract(address, contractABI, signer);
        const id = 2; // <<---!
        const amount = 1;
        yield ERC1551Token.mint(account, id, amount, data);
    });
}
;
setURI_1551 === null || setURI_1551 === void 0 ? void 0 : setURI_1551.addEventListener("click", listenerFunction2);
function listenerFunction2(ev) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const contractABI = [
                "function setURI(string memory newuri) public onlyOwner",
            ];
            //const URI =
            //"https://bafybeicby6arn6qd3tt7bowztkgsyfwnnyw7u2mx6obe4dnif5i5we5z5m.ipfs.dweb.link/0000000000000000000000000000000000000000000000000000000000000000.json";
            //const URI =
            //"https://bafybeiabeso4sz4hviipt5b4y7xlnmvxx2ttjeotl7bugmw77d7ppt2b7e.ipfs.dweb.link/0000000000000000000000000000000000000000000000000000000000000001.json";
            const URI = "https://bafybeiaa6gsgjhj7jn74ejdzevyaa7zk6koc7ofnablx4k7axbh4h6pliu.ipfs.dweb.link/0000000000000000000000000000000000000000000000000000000000000002.json";
            //const address = '0x8db7cb809683c6e691a6419f6064f2f44ed14a10'; // Polygon
            const address = "0x756a566f73e4ff3cf98e83995957ed1e8bcff694"; // Polygon
            //const address = '0x9E279ff142633763867E41924D03BD1B2AD03289'; //Goerli
            const contractObject = new ethers.Contract(address, contractABI, signer);
            // @ts-ignore
            const result = yield contractObject.setURI(URI);
            // alert(result);
        }
        catch (err) {
            throw err;
        }
    });
}
;
getURI_1551.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const contractABI = [
            "function uri(uint256 _id) external view returns (string memory)",
        ];
        //const address = '0x8db7cb809683c6e691a6419f6064f2f44ed14a10'; // Polygon
        //const address = '0x9e279ff142633763867e41924d03bd1b2ad03289'; // Polygon
        const address = "0x756a566f73e4ff3cf98e83995957ed1e8bcff694"; // Polygon
        //const address = '0x9E279ff142633763867E41924D03BD1B2AD03289'; //Goerli
        const contractObject = new ethers.Contract(address, contractABI, signer);
        const tokenId = 2; // <<---!
        const result = yield contractObject.uri(tokenId);
        // alert(result);
        /*
            $.getJSON(result, function (data) {
                // JSON result in `data` variable
                document.getElementById("myImageId").src = data.image;
            });
            */
        $.getJSON(result, function (data) {
            // JSON result in `data` variable
            // @ts-ignore
            document.getElementById("myVideoId").src = data.animation_url;
        });
        var video = document.getElementById("myVideoId");
        video.style.display = "inline";
    }
    catch (err) {
        throw err;
    }
});
DeployNft_ERC721.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    //NFTFactory = new ethers.ContractFactory(metadataNFT.abi, metadataNFT.bytecode, signer);
    NFTFactory = new ethers.ContractFactory(metadataNFT_PHIMAGIC_721.abi, metadataNFT_PHIMAGIC_721.bytecode, signer);
    try {
        NFTContract = yield NFTFactory.deploy();
        yield NFTContract.deployTransaction.wait();
    }
    catch (error) {
        console.log("Deployment Failed");
        throw error;
    }
    if (NFTContract.address === undefined) {
        return;
    }
    lastaddressNFT_721 = NFTContract.address;
    console.log(`Contract mined! address: ${NFTContract.address} transactionHash: ${NFTContract.deployTransaction.hash}`);
});
Mint_721.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    const address = lastaddressNFT_721;
    //const address = "0xd8b2445f938e98e47e0c57ae60759c4c51d50c5f";
    const URI = "https://bafybeih5rjae5abmf5kydzyx2z62b5tz4ct3igpexgxyrhyflqeinsqdhm.ipfs.dweb.link/0000000000000000000000000000000000000000000000000000000000000000.json";
    //const ERC721Token = new ethers.Contract(lastaddressNFT, metadataNFT.abi, signer);
    const ERC721Token = new ethers.Contract(address, metadataNFT_PHIMAGIC_721.abi, signer);
    yield ERC721Token.safeMint(account, URI);
});
setURI_721.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const contractABI = [
            "function setURI(uint256 tokenId, string memory newuri)",
        ];
        const URI = "https://bafybeiabeso4sz4hviipt5b4y7xlnmvxx2ttjeotl7bugmw77d7ppt2b7e.ipfs.dweb.link/0000000000000000000000000000000000000000000000000000000000000001.json";
        //const address = '0xE17217e4D61Cd4B9673e90FF15354032DA33F09d'; // Polygon
        //const address = lastaddressNFT_721;
        const address = "0xd8b2445f938e98e47e0c57ae60759c4c51d50c5f";
        const tokenId = 0;
        const contractObject = new ethers.Contract(address, contractABI, signer);
        const result = yield contractObject.setURI(tokenId, URI);
        // alert(result);
    }
    catch (err) {
        throw err;
    }
});
getURI_721.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        //const address = lastaddressNFT_721;
        const address = "0xd8b2445f938e98e47e0c57ae60759c4c51d50c5f";
        const contractABI = metadataNFT_PHIMAGIC_721.abi;
        const contractObject = new ethers.Contract(address, contractABI, signer);
        const tokenId = 0;
        const result = yield contractObject.tokenURI(tokenId);
        // alert(result);
        $.getJSON(result, function (data) {
            // JSON result in `data` variable
            const img = document.getElementById("myImageId");
            img.src = data.image;
        });
        var img = document.getElementById("myImageId");
        img.style.display = "inline";
    }
    catch (err) {
        throw err;
    }
});
///////
createToken.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    hstFactory = new ethers.ContractFactory(metadataNFT_ERC20.abi, metadataNFT_ERC20.bytecode, signer);
    try {
        hstContract = yield hstFactory.deploy();
        yield hstContract.deployTransaction.wait();
    }
    catch (error) {
        console.log("Creation Failed");
        throw error;
    }
    if (hstContract.address === undefined) {
        return;
    }
    console.log(`Contract mined! address: ${hstContract.address} transactionHash: ${hstContract.deployTransaction.hash}`);
    //console.log(hstContract.address);
});
//transfer to wallet
watchAsset.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield ethereum.request({
        method: "wallet_watchAsset",
        params: {
            type: "ERC20",
            options: {
                address: hstContract.address,
                symbol: "PHI",
                decimals: 4,
                image: "https://metamask.github.io/test-dapp/metamask-fox.svg",
            },
        },
    });
    console.log("result", result);
});
Deploy.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    piggybankFactory = new ethers.ContractFactory(metadata.abi, metadata.bytecode, signer);
    try {
        piggybankContract = yield piggybankFactory.deploy();
        yield piggybankContract.deployTransaction.wait();
    }
    catch (error) {
        // @ts-ignore
        contractStatus.innerHTML = "Deployment Failed";
        throw error;
    }
    if (piggybankContract.address === undefined) {
        return;
    }
    lastaddress = piggybankContract.address;
    console.log(`Contract mined! address: ${piggybankContract.address} transactionHash: ${piggybankContract.deployTransaction.hash}`);
});
Withdraw.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield piggybankContract.withdraw(e2w(0.0005), {
        from: account,
    });
    console.log(result);
    const receipt = yield result.wait();
    console.log(receipt);
});
Deposit.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    const result = piggybankContract.deposit({
        value: e2w(0.001),
    });
    const receipt = yield result.wait();
    console.log(receipt);
});
SignV4.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    Sign(account).then(function (val) {
        ww(account, val);
    });
});
//filewrite();
//fileread();
function Sign(From) {
    return __awaiter(this, void 0, void 0, function* () {
        const chainId = yield ethereum.request({
            method: "eth_chainId",
        });
        const msgParams = {
            domain: {
                chainId: chainId,
                name: "Ether Mail",
                verifyingContract: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC",
                version: "1",
            },
            message: {
                contents: "Hello, Bob!",
                from: {
                    name: "Cow",
                    wallets: [
                        "0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826",
                        "0xDeaDbeefdEAdbeefdEadbEEFdeadbeEFdEaDbeeF",
                    ],
                },
                to: [
                    {
                        name: "Bob",
                        wallets: [
                            "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
                            "0xB0BdaBea57B0BDABeA57b0bdABEA57b0BDabEa57",
                            "0xB0B0b0b0b0b0B000000000000000000000000000",
                        ],
                    },
                ],
            },
            primaryType: "Mail",
            types: {
                EIP712Domain: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "version",
                        type: "string",
                    },
                    {
                        name: "chainId",
                        type: "uint256",
                    },
                    {
                        name: "verifyingContract",
                        type: "address",
                    },
                ],
                Group: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "members",
                        type: "Person[]",
                    },
                ],
                Mail: [
                    {
                        name: "from",
                        type: "Person",
                    },
                    {
                        name: "to",
                        type: "Person[]",
                    },
                    {
                        name: "contents",
                        type: "string",
                    },
                ],
                Person: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "wallets",
                        type: "address[]",
                    },
                ],
            },
        };
        const sign = yield ethereum.request({
            method: "eth_signTypedData_v4",
            params: [From, JSON.stringify(msgParams)],
        });
        return sign;
    });
}
function ww(From, sign) {
    return __awaiter(this, void 0, void 0, function* () {
        const chainId = yield ethereum.request({
            method: "eth_chainId",
        });
        const msgParams = {
            domain: {
                chainId: chainId,
                name: "Ether Mail",
                verifyingContract: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC",
                version: "1",
            },
            message: {
                contents: "Hello, Bob!",
                from: {
                    name: "Cow",
                    wallets: [
                        "0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826",
                        "0xDeaDbeefdEAdbeefdEadbEEFdeadbeEFdEaDbeeF",
                    ],
                },
                to: [
                    {
                        name: "Bob",
                        wallets: [
                            "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
                            "0xB0BdaBea57B0BDABeA57b0bdABEA57b0BDabEa57",
                            "0xB0B0b0b0b0b0B000000000000000000000000000",
                        ],
                    },
                ],
            },
            primaryType: "Mail",
            types: {
                EIP712Domain: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "version",
                        type: "string",
                    },
                    {
                        name: "chainId",
                        type: "uint256",
                    },
                    {
                        name: "verifyingContract",
                        type: "address",
                    },
                ],
                Group: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "members",
                        type: "Person[]",
                    },
                ],
                Mail: [
                    {
                        name: "from",
                        type: "Person",
                    },
                    {
                        name: "to",
                        type: "Person[]",
                    },
                    {
                        name: "contents",
                        type: "string",
                    },
                ],
                Person: [
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "wallets",
                        type: "address[]",
                    },
                ],
            },
        };
        const recoveredAddr = recoverTypedSignature({
            // @ts-ignore
            data: msgParams,
            signature: sign,
            // @ts-ignore
            version: "V4",
        });
        console.log(recoveredAddr);
        //alert(recoveredAddr);
        if (toChecksumAddress(recoveredAddr) === toChecksumAddress(From)) {
            alert(recoveredAddr);
        }
    });
}
function requestPermissions() {
    ethereum
        .request({
        method: "wallet_requestPermissions",
        params: [
            {
                eth_accounts: {},
            },
        ],
    })
        .then((permissions) => {
        const accountsPermission = permissions.find((permission) => permission.parentCapability === "eth_accounts");
        if (accountsPermission) {
            alert("eth_accounts permission successfully requested!");
        }
    })
        .catch((error) => {
        if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            alert("Permissions needed to continue.");
        }
        else {
            alert(error);
        }
    });
}
//ETHER to WEI
function e2w(val) {
    var v = "0x" + (val * Math.pow(10, 18)).toString(16);
    //console.log(v);
    return v;
}
//WEI to ETHER
function w2e(val) {
    var v = "0x" + (val * Math.pow(10, -18)).toString(16);
    //console.log(v);
    return v;
}
function filewrite() {
    return __awaiter(this, void 0, void 0, function* () {
        $.ajax({
            type: "POST",
            url: "writefile.php",
            data: {
                filename: "filewrite.txt",
                something: "foo",
            },
            success: function (data) {
                alert(data);
            },
        });
    });
}
function fileread() {
    return __awaiter(this, void 0, void 0, function* () {
        $.ajax({
            type: "POST",
            url: "readfile.php",
            data: {
                filename: "filewrite.txt",
            },
            success: function (data) {
                alert(data);
            },
        });
    });
}
function hex_to_ascii(str1) {
    var hex = str1.toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}
